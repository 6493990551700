// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cls-1,
.cls-13 {
  fill: #ca1818;
}
.cls-1,
.cls-10,
.cls-12,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7,
.cls-8,
.cls-9 {
  stroke: #000;
  stroke-miterlimit: 10;
  cursor: pointer;
}
.cls-2 {
  fill: #e03636;
}
.cls-3 {
  fill: #e04e4e;
}
.cls-4 {
  fill: #e76d6c;
}
.cls-14,
.cls-5 {
  fill: #e7ce4f;
}
.cls-6 {
  fill: #f3dc77;
}
.cls-7 {
  fill: #fee884;
}
.cls-8 {
  fill: #80b65e;
}
.cls-9 {
  fill: #71a854;
}
.cls-10,
.cls-15 {
  fill: #5f9643;
}
.cls-11 {
  fill: #231f20;
}
.cls-12 {
  fill: none;
}
.st16 {
  font-size: 36px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/forms/my-life-wheel/styles.less"],"names":[],"mappings":"AAAA;;EAEE,aAAA;AACF;AAEA;;;;;;;;;;;EAWE,YAAA;EACA,qBAAA;EACA,eAAA;AAAF;AAGA;EACE,aAAA;AADF;AAIA;EACE,aAAA;AAFF;AAKA;EACE,aAAA;AAHF;AAMA;;EAEE,aAAA;AAJF;AAOA;EACE,aAAA;AALF;AAQA;EACE,aAAA;AANF;AASA;EACE,aAAA;AAPF;AAUA;EACE,aAAA;AARF;AAWA;;EAEE,aAAA;AATF;AAYA;EACE,aAAA;AAVF;AAaA;EACE,UAAA;AAXF;AAcA;EACE,eAAA;AAZF","sourcesContent":[".cls-1,\n.cls-13 {\n  fill: #ca1818;\n}\n\n.cls-1,\n.cls-10,\n.cls-12,\n.cls-2,\n.cls-3,\n.cls-4,\n.cls-5,\n.cls-6,\n.cls-7,\n.cls-8,\n.cls-9 {\n  stroke: #000;\n  stroke-miterlimit: 10;\n  cursor: pointer;\n}\n\n.cls-2 {\n  fill: #e03636;\n}\n\n.cls-3 {\n  fill: #e04e4e;\n}\n\n.cls-4 {\n  fill: #e76d6c;\n}\n\n.cls-14,\n.cls-5 {\n  fill: #e7ce4f;\n}\n\n.cls-6 {\n  fill: #f3dc77;\n}\n\n.cls-7 {\n  fill: #fee884;\n}\n\n.cls-8 {\n  fill: #80b65e;\n}\n\n.cls-9 {\n  fill: #71a854;\n}\n\n.cls-10,\n.cls-15 {\n  fill: #5f9643;\n}\n\n.cls-11 {\n  fill: #231f20;\n}\n\n.cls-12 {\n  fill: none;\n}\n\n.st16 {\n  font-size: 36px;\n}\n\n@primary-color: #a700ba;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
