export const colorNameToHex = (colorMap, name) => {
  const color = colorMap.find((color) => color.name === name);

  const hex = color ? color.hex : undefined;

  return hex;
};

export const colorHexToName = (colorMap, hex) => {
  const color = colorMap.find(
    (color) => color.hex.toLowerCase() === hex.toLowerCase(),
  );

  const name = color ? color.name : undefined;

  return name;
};

export const hexToRGBA = (hex, opacity) => {
  return (
    'rgba(' +
    (hex = hex.replace('#', ''))
      .match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))
      .map(function (l) {
        return parseInt(hex.length % 2 ? l + l : l, 16);
      })
      .concat(isFinite(opacity) ? opacity : 1)
      .join(',') +
    ')'
  );
};
