import {
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  REFRESH_USER,
  REGISTER_FAIL_REDIRECT_REFERAL,
  LOGIN_REFERAL,
  LOGIN_SUCCESS_REFERAL,
  LOGIN_FAIL_REFERAL,
  LOGIN_REQUEST_REFERAL,
  REGISTER_SUCCESS
} from '../actions/types';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = {
  isLoggedIn: Boolean(user),
  user: user || null,
  loading: false,
  error: '',
};
const ranksConstant = [
  'White',
  'Red',
  'Orange',
  'Yellow',
  'Green',
  'Blue',
  'Indigo',
  'Violet',
  'Black',
];

const mapUser = (user) => {
  const safePoints = user.points || 0;
  const rankNumber = ranksConstant.indexOf(user.rank) + 1;

  const credit = (safePoints / 100) * (rankNumber * 1);

  return { credit, ...user };
};
const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: mapUser(payload.user, action.type),
        redirectTo: payload.redirectTo,
        loading: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: mapUser(payload.user, action.type),
        redirectTo: payload.redirectTo,
        loading: false,
      };

    case REFRESH_USER:
      return {
        ...state,
        isLoggedIn: true,
        user: mapUser(payload.user),
        loading: false,
      };

    case LOGIN_FAIL:
      localStorage.removeItem('user');
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        error: '401',
        loading: false,
      };

    case REGISTER_FAIL_REDIRECT_REFERAL:
      localStorage.removeItem('user');
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        redirectTo: payload.redirectTo,
        loading: false,
      };

    case LOGIN_FAIL_REFERAL:
      localStorage.removeItem('user');
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        error: '401',
        loading: false,
      };
    case LOGIN_SUCCESS_REFERAL:
      return {
        ...state,
        isLoggedIn: true,
        user: mapUser(payload.user, action.type),
        redirectTo: payload.redirectTo,
        loading: false,
      };
    case LOGIN_REQUEST_REFERAL:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT:
      localStorage.removeItem('user');
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        user: null,
      };

    default:
      return state;
  }
};

export default userReducer;
