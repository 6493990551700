import request from '../../shared/lib/api';

const getProfile = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/auth/profile`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const updateProfile = (body) => {
  const user = JSON.parse(localStorage.getItem('user'));
  return request({
    url: `/updateProfile/${user.id}`,
    method: 'PATCH',
    data: {
      ...body,
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

export default {
  getProfile,
  updateProfile
};
