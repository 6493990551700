export const limmiter = (text, maxLength) => {
  const { length } = text;

  let suffix = '';
  if (length > maxLength) {
    suffix = '...';
  }

  return `${text.substring(0, maxLength)}${suffix}`;
};
