/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  DownOutlined,
  UserOutlined,
  AimOutlined,
  HeatMapOutlined,
  HeartOutlined,
  ClusterOutlined,
  CheckCircleOutlined,
  BarChartOutlined,
  BookOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Dropdown,
  Layout,
  Menu,
  Row,
  Space,
} from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../../assets/logo.png';
import './styles.less';
import UserMenu from './top-menu/UserMenu';
import MainMenu from './top-menu/MainMenu';

// components
const SideBar = () => {
  const location = useLocation();
  const [backtohome, setBacktohome] = useState(true);

  const myLifeMasteryMenu =
    location.pathname == '/my-life' ? (
      <Menu.Item key="/my-life">
        <Link to="/my-life" style={{ pointerEvents: 'none', marginLeft: '5%' }}>
          My Life Mastery Navigator
        </Link>
      </Menu.Item>
    ) : null;

  return (
    <Layout.Sider
      width="250"
      style={{ boxShadow: '14px -1px 12px -17px rgba(0,0,0,0.75)' }}
      collapsible
      defaultCollapsed={false}
      className="sidebar"
      onCollapse={(collapsed) => {
        setBacktohome(collapsed);
        // alert("COL" );
      }}
    >
      <Row justify="center" style={{ padding: '0.7em', paddingTop: '3em' }}>
        {/* <Space size={[0]} align="center" direction="vertical" wrap> */}
        <a href={ process.env.PUBLIC_URL } style={{ textAlign: 'center' }}>
          <img
            src={Logo}
            className="logo"
            alt="logo"
            style={{ width: '70%' }}
          />
        </a>
        {backtohome ? (
          <></>
        ) : (
          <a
            href={ process.env.PUBLIC_URL }
            target="_blank"
            rel="noopener noreferrer"
            style={{ padding: '1em', paddingTop: '1.5em' }}
          >
            <Button type="primary" size="medium">
              BACK TO HOME
            </Button>
          </a>
        )}

        {/* </Space> */}
      </Row>
      <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
        <Menu.Item icon={<BarChartOutlined />} key="/">
          <Link to="/">Dashboard</Link>
        </Menu.Item>

        <Menu.SubMenu icon={<BookOutlined />} title="My Life Book">
          <Menu.Item icon={<CheckCircleOutlined />} key="/my-goals">
            <Link to="/my-goals">My Goals</Link>
          </Menu.Item>
          {myLifeMasteryMenu}
          <Menu.Item icon={<HeatMapOutlined />} key="/core-values">
            <Link to="/core-values">My Core Values</Link>
          </Menu.Item>
          <Menu.Item icon={<ClusterOutlined />} key="/my-road">
            <Link to="/my-road">My Life Map (Ranks)</Link>
          </Menu.Item>
          <Menu.Item icon={<AimOutlined />} key="/wheel">
            <Link to="/wheel">My Life Target</Link>
          </Menu.Item>
 
        </Menu.SubMenu>
        <Menu.Item icon={<HeartOutlined />} key="/my-health-wellness">
            <Link to="/my-health-wellness">My Health & Wellness Book</Link>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
};

const TopMenu = () => {
  // data
  const auth = useSelector((state) => state.auth);
  const { loading, user } = auth;
  const safeuser = user || {
    rank: '',
    plus: '',
    points: 0,
    credit: 0,
    username: '',
    avatarUrl: '',
    role: '',
    serial: '',
  };

  // components
  const userMenu = <UserMenu loading={ loading } safeuser={ safeuser } />;
  const mainMenu = <MainMenu />;

  return (
    <Row justify="space-between" align="middle">
      {mainMenu}

      <Menu
        mode="horizontal"
        style={{ padding: '20px 0', backgroundColor: 'transparent' }}
      >
        <div style={{ marginLeft: 'auto', marginRight: '20px' }}>
          <Space>
            <Dropdown
              overlay={userMenu}
              trigger={['click']}
              placement="bottomRight"
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {safeuser.username} <DownOutlined />
              </a>
            </Dropdown>
            <Avatar
              shape="circle"
              size="large"
              src={safeuser.avatarUrl}
              icon={<UserOutlined />}
            />
          </Space>
        </div>
      </Menu>
    </Row>
  );
};

const AppLayout = ({ children }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <Layout id="app-layout" style={{ minHeight: '100vh' }}>
      {auth.isLoggedIn ? <SideBar /> : null}
      <Layout className="site-layout">
        <Layout.Content style={{ margin: '16px' }}>
          {auth.isLoggedIn ? <TopMenu user={auth.user} /> : null}
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            {children}
          </div>
        </Layout.Content>
        <Layout.Footer style={{ textAlign: 'center' }}>
          &copy; Copyright 2021 HEAT
        </Layout.Footer>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
