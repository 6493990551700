import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Space,
  Table,
  Button,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import WheelService from '../../../../services/api/WheelService';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === 'number' ? (
      <InputNumber min={0} max={10} style={{ width: '100%' }} />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
            {
              type: 'number',
              min: 0,
            },
            {
              type: 'number',
              max: 10,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const WheelTable = ({ refresh, setRefresh, wheelRefresh }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    WheelService.getAllWheels()
      .then((res) => res.data.data)
      .then((data) => {
        let withKeyData = data.wheels.map((item) => ({
          ...item,
          key: item.id,
        }));
        withKeyData.sort((a,b)=>{ return  a.createdAt - b.createdAt });
        // console.log(newnew.reverse());
        setData(withKeyData.reverse());
      })
      .finally(() => setLoading(false));
  }, [ wheelRefresh ])

  const isEditing = (record) => record?.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      pointBody: '',
      pointMind: '',
      pointSocial_conflict: '',
      pointSocial_education: '',
      pointSocial_environment: '',
      pointSocial_love: '',
      pointSocial_money: '',
      pointSocial_sexuality: '',
      pointSocial_success: '',
      pointSoul: '',
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const deleteWheel = async (key) => {
    setLoading(true);
    try {
      const newData = [...data];

      await WheelService.deleteWheel(key);
      const filtredData = newData.filter((item) => key !== item.id);
      setData(filtredData);
      setEditingKey('');
      setRefresh(refresh + 1);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
    setLoading(false);
  };

  const save = async (key) => {
    setLoading(true);
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.id);

      if (index > -1) {
        const item = newData[index];
        const updated = await WheelService.editWheel(key, { ...item, ...row });
        newData.splice(index, 1, updated.data.data.wheel);
        setData(newData);
        setEditingKey('');
        setRefresh(refresh + 1);
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
    setLoading(false);
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        return moment(createdAt).format('LLLL');
      },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: (_, rest) => {
        const data = Object.keys(rest).filter((key) => key.startsWith('point'));
        const values = data.map((key) => rest[key]);

        const sum = values.reduce((a, b) => a + b, 0);
        const notZeros = values.filter((val) => val);
        const result = `${sum}/${notZeros.length * 10}`;

        return result;
      },
    },
    {
      title: 'BODY',
      dataIndex: 'pointBody',
      editable: true,
    },
    {
      title: 'MIND',
      dataIndex: 'pointMind',
      editable: true,
    },

    {
      title: 'SOUL',
      dataIndex: 'pointSoul',
      editable: true,
    },

    {
      title: 'SOCIAL - RELATIONSHIP/LOVE',
      dataIndex: 'pointSocial_love',
      editable: true,
    },

    {
      title: 'SOCIAL - SEXUALITY',
      dataIndex: 'pointSocial_sexuality',
      editable: true,
    },
    {
      title: 'SOCIAL - MONEY/INCOME',
      dataIndex: 'pointSocial_money',
      editable: true,
    },
    {
      title: 'SOCIAL - CAEEER/OCCUPATION',
      dataIndex: 'pointSocial_success',
      editable: true,
    },
    {
      title: 'SOCIAL - ENVIRONMENT',
      dataIndex: 'pointSocial_environment',
      editable: true,
    },
    {
      title: 'SOCIAL - PEACE/CONFLICT',
      dataIndex: 'pointSocial_conflict',
      editable: true,
    },
    {
      title: 'SOCIAL - EDUCATION',
      dataIndex: 'pointSocial_education',
      editable: true,
    },

    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Typography.Link>Cancel</Typography.Link>
            </Popconfirm>
          </span>
        ) : (
          <Space direction="horizontal" align="end" wrap>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              <Button type="primary" icon={<EditOutlined />} size="small" />
            </Typography.Link>

            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => deleteWheel(record.id)}
            >
              <Button icon={<DeleteOutlined />} size="small" danger />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      { data != '' &&
      <Table
        loading={loading}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
        size="small"
        scroll={{ x: 'calc(700px + 50%)', y: ' 100%' }}
      />
      }
    </Form>
  );
};

export default WheelTable;
