import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Menu, Spin, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutUser } from '../../../../store/actions/authActions';

function UserMenu ({ safeuser, loading }) {
    const dispatch = useDispatch();

    const onLogoutClick = () => {
        window.location.href = process.env.PUBLIC_URL;
        dispatch(logoutUser());
    };

    return (
        <Menu mode="horizontal">
            <Menu.Item key="/profile/serial">
            <Typography.Text>ID:</Typography.Text>{' '}
            <Typography.Text strong>{safeuser.serial + 1000}</Typography.Text>
            </Menu.Item>
            <Menu.Item key="/profile/role">
            <Typography.Text>Role:</Typography.Text>{' '}
            <Typography.Text strong>{safeuser.role}</Typography.Text>
            </Menu.Item>

            <Menu.Item key="1">
            <Typography.Text>Rank: </Typography.Text>{' '}
            <Typography.Text strong>
                {loading ? <Spin /> : safeuser.rank}
            </Typography.Text>
            <Typography.Text strong> {safeuser.plus ? '+' : ''}</Typography.Text>
            </Menu.Item>
            <Menu.Item key="/profile/points">
            <Link to="/profile/points">
                HEAT Points:{' '}
                {loading ? (
                <Spin />
                ) : (
                <Typography.Text strong>{safeuser.points}</Typography.Text>
                )}
            </Link>
            </Menu.Item>
            <Menu.Item key="/profile/credit">
            <Link to="/profile/points">
                Credit:{' '}
                {loading ? (
                <Spin />
                ) : (
                <Typography.Text strong>
                    ${safeuser.credit?.toFixed(2)}
                </Typography.Text>
                )}
            </Link>
            </Menu.Item>

            <Menu.Divider />

            <Menu.Item key="2" onClick={(e) => {}} icon={<UserOutlined />}>
                <Link to="/Profile"> Profile </Link>
            </Menu.Item>

            <Menu.Divider />

            <Menu.Item
                key="3"
                onClick={onLogoutClick}
                icon={<LogoutOutlined />}
                danger
            >
                Logout
            </Menu.Item>
        </Menu>
    )
}

export default UserMenu
