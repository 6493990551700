import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { loginUserWithEmail } from '../../../store/actions/authActions';
import AuthService from '../../../services/api/AuthService';

const ForgotPassword = () => {
  const auth = useSelector((state) => state.auth);
  const [sentEmail, setSentEMail] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (auth.isLoggedIn) {
      const redirectTo = auth?.redirectTo ? auth?.redirectTo : '/';
      history.push(location.state?.form.pathname || redirectTo);
    }
  }, [auth, location, history]);

  const finish = (
    <>
    <Space direction={'vertical'}>
      <img
        width={'100%'}
        height={200}
        style={{ marginTop: '-30%' }}
        src="./HEAT-Final-copy-324x324.png"
        align="center"
      />
      <Typography>A reset link has been sent to your email.</Typography>
    <Row>
    <Button
        style={{ margin: '1%' }}
        type="primary"
        htmlType="submit"
        href="/login"
      >
        Login
      </Button>
    </Row>
    </Space>
    </>
  );

  const onFinish = (values) => {
    AuthService.forgotPassword(values);

    setSentEMail(false);
  };

  return (
    <>
      <Row
        justify="center"
        style={{
          backgroundImage: `url('./soul.jpg')`,
          backgroundColor: 'transparent',
          backgroundSize: 'cover',
        }}
      >
        <Col
          sm={8}
          lg={6}
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            padding: '5%',
            marginTop: '5%',
            marginBottom: '10%',
            borderRadius: '20px',
            minHeight: '50vh',
          }}
        >
          {sentEmail ? (
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <img
                width={'100%'}
                height={200}
                style={{ marginTop: '-30%' }}
                src="./HEAT-Final-copy-324x324.png"
                align="center"
              />
              <Typography style={{ padding: '2%' }}>
                Please enter your email
              </Typography>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Please input your Email!' },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  type="email"
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item>
                <Row justify="space-between" align="middle">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={auth.loading}
                  >
                    Submit
                  </Button>
                  <span>
                    <Link to="/login">login</Link>
                  </span>
                </Row>
              </Form.Item>
            </Form>
          ) : (
            finish
          )}
        </Col>
      </Row>
    </>
  );
};

export default ForgotPassword;
