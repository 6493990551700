// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.github-picker {
  width: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/wrappers/inputs/styles.less"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ","sourcesContent":[".github-picker {\n    width: 100% !important;\n}\n\n\n@primary-color: #a700ba;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
