// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wellnesssearch .ant-select-selector {
  border-radius: 40px !important;
}
.wellnesssearch .ant-select-selector:hover {
  box-shadow: -1px 1px 9px 2px rgba(0, 0, 0, 0.17);
}
.question-section .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/forms/my-health-wellness/styles.less"],"names":[],"mappings":"AAAA;EAEE,8BAAA;AAAF;AAKA;EAEE,gDAAA;AAJF;AAWA;EAGI,aAAA;AAXJ","sourcesContent":[".wellnesssearch .ant-select-selector\n{\n  border-radius: 40px !important;\n  // box-shadow: -1px 1px 9px 2px rgba(0,0,0,0.17);\n\n\n}\n.wellnesssearch .ant-select-selector:hover\n{\n  box-shadow: -1px 1px 9px 2px rgba(0,0,0,0.17);\n\n\n}\n.ant-select-selector {\n}\n\n.question-section {\n  .ant-form-item-label\n    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {\n    display: none;\n\n  }\n}\n\n@primary-color: #a700ba;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
