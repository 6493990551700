// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* add css module styles here (optional) */

.styles_test__i9mBD {
  margin: 2em;
  padding: 0.5em;
  border: 2px solid #000;
  font-size: 2em;
  text-align: center;
}

.styles_Stepper__KFDth {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.styles_Step__SljMm {
  flex: 1 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.styles_StepLineThrough__emQNw {
  position: absolute;
  height: 2px;
  top: 55px;
  left: calc(-50% + 16px);
  right: calc(50% + 15px);
  background-color: #e4e8ebf5;
}
.styles_StepLineThroughDone__VSCgC {
  background-color: #de4290;
}

.styles_StepName__ZpQ-X {
  font-size: 24px;
  height: 40px;
  line-height: 40px;
}

.styles_StepShape__U4Vlu {
  width: 25px;
  height: 25px;
  border: 3px solid #de4290;
  border-radius: 50%;
}

.styles_stepDone__Vp07Q {
  background-color: #de4290;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_stepDoneIcon__81KVd {
  height: 15px;
  width: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/navigation/stepper/styles.module.css"],"names":[],"mappings":"AAAA,0CAA0C;;AAE1C;EACE,WAAW;EACX,cAAc;EACd,sBAAsB;EACtB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,SAAO;EACP,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,uBAAuB;EACvB,uBAAuB;EACvB,2BAA2B;AAC7B;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":["/* add css module styles here (optional) */\n\n.test {\n  margin: 2em;\n  padding: 0.5em;\n  border: 2px solid #000;\n  font-size: 2em;\n  text-align: center;\n}\n\n.Stepper {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n}\n\n.Step {\n  flex: 1;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.StepLineThrough {\n  position: absolute;\n  height: 2px;\n  top: 55px;\n  left: calc(-50% + 16px);\n  right: calc(50% + 15px);\n  background-color: #e4e8ebf5;\n}\n.StepLineThroughDone {\n  background-color: #de4290;\n}\n\n.StepName {\n  font-size: 24px;\n  height: 40px;\n  line-height: 40px;\n}\n\n.StepShape {\n  width: 25px;\n  height: 25px;\n  border: 3px solid #de4290;\n  border-radius: 50%;\n}\n\n.stepDone {\n  background-color: #de4290;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.stepDoneIcon {\n  height: 15px;\n  width: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"test": `styles_test__i9mBD`,
	"Stepper": `styles_Stepper__KFDth`,
	"Step": `styles_Step__SljMm`,
	"StepLineThrough": `styles_StepLineThrough__emQNw`,
	"StepLineThroughDone": `styles_StepLineThroughDone__VSCgC`,
	"StepName": `styles_StepName__ZpQ-X`,
	"StepShape": `styles_StepShape__U4Vlu`,
	"stepDone": `styles_stepDone__Vp07Q`,
	"stepDoneIcon": `styles_stepDoneIcon__81KVd`
};
export default ___CSS_LOADER_EXPORT___;
