// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps-content {
  min-height: 200px;
  margin-top: 16px;
  padding-top: 10px;
  /* background-color: #fafafa; */
  /* border: 1px dashed #e9e9e9; */
  /* border-radius: 2px; */
}
.steps-action {
  margin-top: 24px;
}
.life-road-page .ant-steps-icon-dot {
  border: 1px solid #000;
}
`, "",{"version":3,"sources":["webpack://./src/pages/forms/my-life-mastery-road-map/styles.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,+BAA+B;EAC/B,gCAAgC;EAChC,wBAAwB;AAC1B;AAKA;EACE,gBAAA;AAHF;AAMA;EAEI,sBAAA;AALJ","sourcesContent":[".steps-content {\n  min-height: 200px;\n  margin-top: 16px;\n  padding-top: 10px;\n  /* background-color: #fafafa; */\n  /* border: 1px dashed #e9e9e9; */\n  /* border-radius: 2px; */\n}\n\n.steps-description {\n}\n\n.steps-action {\n  margin-top: 24px;\n}\n\n.life-road-page {\n  .ant-steps-icon-dot {\n    border: 1px solid #000;\n  }\n}\n\n@primary-color: #a700ba;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
