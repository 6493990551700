/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  message,
  Row,
  Space,
  Spin,
  Collapse,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import './styles.less';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import WheelSVG from './components/WheelSVG';
import WheelService from '../../../services/api/WheelService';
import WheelTable from './components/WheelTable';
import PageTitle from '../../../components/layout/page-title/PageTitle';
import WheelHelpers from './components/WheelHelpers';

const { Panel } = Collapse;

const MyLifeWheelPage = ({ onChange, setVisible, modal = false }) => {
  const [loading, setLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);

  const [refresh, setRefresh] = useState(0);
  const [wheelRefresh, setWheelRefresh] = useState(0);

  const [imgModal, setImgModal] = useState({
    show: false,
    image: undefined,
  });

  useEffect(() => {
    const $x_center = 860.58;
    const $y_center = 429.95;

    const $pointMind = 'pointMind';
    const $pointBody = 'pointBody';
    const $pointSoul = 'pointSoul';
    const $pointSocial_love = 'pointSocial_love';
    const $pointSocial_sexuality = 'pointSocial_sexuality';
    const $pointSocial_money = 'pointSocial_money';
    const $pointSocial_success = 'pointSocial_success';
    const $pointSocial_environment = 'pointSocial_environment';
    const $pointSocial_conflict = 'pointSocial_conflict';
    const $pointSocial_education = 'pointSocial_education';

    const points = [
      'pointMind',
      'pointBody',
      'pointSoul',
      'pointSocial_sexuality',
      'pointSocial_love',
      'pointSocial_money',
      'pointSocial_success',
      'pointSocial_environment',
      'pointSocial_conflict',
      'pointSocial_education',
    ];

    const $radius = [
      427.9, 387.8, 347.7, 307.6, 267.5, 227.4, 187.3, 147.2, 107.1, 67,
    ];
    const Degree = {
      mind: 51.3,
      body: 38,
      soul: 38,
      socialeducation: 5.5,
      socialMoney: 16.5,
      socialSuccess: 27.5,
      socialLove: 39.5,
      socialSexuality: 51.5,
      socialEnvironment: 66.5,
      socialConflict: 81.5,
    };

    const PointRadius = {
      min: 14,
      max: 18,
    };

    let svg = document.getElementById('wellnessWheel');
    let NS = null;
    if (svg != null) {
      NS = svg.getAttribute('xmlns');
    }

    // Remove all old id point circle
    function removeElement(idName) {
      let Elements = document.querySelectorAll(`#${idName}`);

      for (let i = 0; i < Elements.length; i++) {
        Elements[i].remove();
      }
    }
    // End Remove all old id point circle

    class Wellness {
      //------------------------
      cal_sin(degree) {
        let accuracy = 0.0001;

        // Converting degrees to radian
        degree = degree * (3.142 / 180.0);

        let x1 = degree;

        // maps the sum along the series
        let sinX = degree;

        // holds the actual value of sin(n)
        let sinVal = Math.sin(degree);
        let i = 1;
        do {
          let denominator = 2 * i * (2 * i + 1);
          x1 = (-x1 * degree * degree) / denominator;
          sinX = sinX + x1;
          i++;
        } while (accuracy <= Math.abs(sinVal - sinX));

        return sinX;
      }

      //------------------------
      // Function for calculation
      cal_cos(degree) {
        let accuracy = 0.0001;

        // Converting degrees to radian
        degree = degree * (3.142 / 180.0);

        let x1 = 1;

        // maps the sum along the series
        let cosX = x1;

        // holds the actual value of sin(n)
        let cosVal = Math.cos(degree);
        let i = 1;
        do {
          let denominator = 2 * i * (2 * i - 1);
          x1 = (-x1 * degree * degree) / denominator;
          cosX = cosX + x1;
          i = i + 1;
        } while (accuracy <= Math.abs(cosVal - cosX));

        return cosX;
      }

      //------------------------
      calFindCenterXY(degree, numberSelected, part = 'mind') {
        let math_x = this.cal_cos(degree);
        let math_y = this.cal_sin(degree);

        math_x = ($radius[numberSelected - 1] - 21) * math_x;
        math_y = ($radius[numberSelected - 1] - 21) * math_y;

        let svgPoint = {
          // Default is Mind part calculate
          x: $x_center + math_x,
          y: $y_center - math_y,
        };

        // eslint-disable-next-line default-case
        switch (part) {
          case $pointBody:
            svgPoint.x = $x_center - math_x;
            svgPoint.y = $y_center - math_y;
            break;
          case $pointSoul:
            svgPoint.x = $x_center + math_x;
            svgPoint.y = $y_center + math_y;
            break;
          case $pointSocial_education:
          case $pointSocial_love:
          case $pointSocial_sexuality:
          case $pointSocial_money:
          case $pointSocial_success:
          case $pointSocial_environment:
          case $pointSocial_conflict:
            svgPoint.x = $x_center - math_x;
            svgPoint.y = $y_center + math_y;
            break;
        }
        return svgPoint;
      }

      //------------------------
      // Find Number Of Point Clicked
      getPointClicked(object, x = -1, y = -1) {
        if (object != null) {
          x = object.getAttribute('cx');
          y = object.getAttribute('cy');
          // eslint-disable-next-line eqeqeq
        } else if (x == -1 && object == null) {
          return 0; // null
        }

        x = $x_center - x;
        y = $y_center - y;

        let R = this.getRadius(x, y);

        let point = (R - $radius[9]) / 40.1; // 40.1 space of any radius
        point = Math.floor(10 - point);

        return point;
      }

      //------------------------
      // Find Part Social Aspects Clicked
      getPartSocialAspectsClicked(x, y) {
        x = $x_center - x;
        y = $y_center - y;

        let R = this.getRadius(x, y);

        let degreeSin = -y / R;
        let ret = Math.asin(degreeSin);
        let degree = (ret * 180) / Math.PI;

        let partSocial = Math.floor(degree / 12) + 1;

        return partSocial;
      }

      //------------------------
      getRadius(x, y) {
        let R = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));

        return R;
      }

      //------------------------
      // translate page to SVG
      svgPoint(element, x, y) {
        let pt = svg.createSVGPoint();
        pt.x = x;
        pt.y = y;

        return pt.matrixTransform(element.getScreenCTM().inverse());
      }

      //------------------------
      // Function startMove
      startMove(obj, r1, r2) {
        let nowR = r1;
        let targetR = r2;
        let speed = 0;
        clearInterval(obj.timer);
        obj.timer = setInterval(function () {
          speed += (targetR - nowR) / 6;
          speed *= 0.9;
          if (Math.abs(targetR - nowR) <= 1 && Math.abs(speed) <= 1) {
            clearInterval(obj.timer);
            obj.setAttribute('r', targetR);
          } else {
            nowR += speed;
            obj.setAttribute('r', nowR);
          }
        }, 30);
      }

      createCircle(target, idName, numberSelected, svgP, svgOb) {
        let circle = document.createElementNS(NS, 'circle');

        if (NS == null) {
          return;
        }

        circle.setAttributeNS(null, 'cx', Math.round(svgP.x));
        circle.setAttributeNS(null, 'cy', Math.round(svgP.y));
        circle.setAttributeNS(null, 'r', 14);
        circle.setAttributeNS(null, 'id', idName);
        circle.setAttributeNS(null, 'class', 'point-draw');

        if (target && target != null) target.appendChild(circle);

        circle.onmouseenter = function () {
          WellnessWheel.startMove(circle, PointRadius.min, PointRadius.max);
        };
        circle.onmouseleave = function () {
          WellnessWheel.startMove(circle, PointRadius.max, PointRadius.min);
        };
        // circle.onmousemove = function () {
        //     WellnessWheel.showTooltip(svgOb.x, svgOb.y, 'This is blue');
        // };
        // circle.onmouseout = function () {
        //     WellnessWheel.hideTooltip();
        // };

        setTimeout(function () {
          circle.setAttributeNS(null, 'cx', Math.round(svgOb.x));
          circle.setAttributeNS(null, 'cy', Math.round(svgOb.y));
        }, 500);

        let tooltipSpan = document.createElementNS(NS, 'title');
        let textNode = document.createTextNode(numberSelected.toString());
        tooltipSpan.appendChild(textNode);
        circle.appendChild(tooltipSpan);
      }

      //------------------------
      generatePoint(x, y, target) {
        let svgP = this.svgPoint(target, x, y);

        let numberSelected = this.getPointClicked(null, svgP.x, svgP.y);

        let idName = '';
        let svgOb = {};

        if (svgP.x > $x_center && svgP.y < $y_center) {
          // Part Mind
          idName = $pointMind;
          svgOb = this.calFindCenterXY(Degree.mind, numberSelected, $pointMind);
        } else if (svgP.x < $x_center && svgP.y < $y_center) {
          // Part Body
          idName = $pointBody;
          svgOb = this.calFindCenterXY(Degree.body, numberSelected, $pointBody);
        } else if (svgP.x > $x_center && svgP.y > $y_center) {
          // Part Soul
          idName = $pointSoul;
          svgOb = this.calFindCenterXY(Degree.soul, numberSelected, $pointSoul);
        } else if (svgP.x < $x_center && svgP.y > $y_center) {
          // Part Social Aspects
          let part = WellnessWheel.getPartSocialAspectsClicked(svgP.x, svgP.y);

          // eslint-disable-next-line default-case
          switch (part) {
            case 1:
              idName = $pointSocial_education;
              svgOb = this.calFindCenterXY(
                Degree.socialeducation,
                numberSelected,
                $pointSocial_education,
              );
              break;
            case 2:
              idName = $pointSocial_money;
              svgOb = this.calFindCenterXY(
                Degree.socialMoney,
                numberSelected,
                $pointSocial_money,
              );
              break;
            case 3:
              idName = $pointSocial_success;
              svgOb = this.calFindCenterXY(
                Degree.socialSuccess,
                numberSelected,
                $pointSocial_success,
              );
              break;

            case 4:
              idName = $pointSocial_love;
              svgOb = this.calFindCenterXY(
                Degree.socialLove,
                numberSelected,
                $pointSocial_love,
              );
              break;

            case 5:
              idName = $pointSocial_sexuality;
              svgOb = this.calFindCenterXY(
                Degree.socialSexuality,
                numberSelected,
                $pointSocial_sexuality,
              );
              break;
            case 6:
              idName = $pointSocial_environment;
              svgOb = this.calFindCenterXY(
                Degree.socialEnvironment,
                numberSelected,
                $pointSocial_environment,
              );
              break;
            case 7:
              idName = $pointSocial_conflict;
              svgOb = this.calFindCenterXY(
                Degree.socialConflict,
                numberSelected,
                $pointSocial_conflict,
              );
              break;
          }
        }

        // Remove all old id point circle
        removeElement(idName);
        // End Remove all old id point circle

        this.createCircle(target, idName, numberSelected, svgP, svgOb);
      }

      generatePointFromApi(point, value) {
        const Degrees = {
          pointMind: 51.3,
          pointBody: 38,
          pointSoul: 38,
          pointSocial_education: 5.5,
          pointSocial_money: 16.5,
          pointSocial_success: 27.5,
          pointSocial_love: 39.5,
          pointSocial_sexuality: 51.5,
          pointSocial_environment: 66.5,
          pointSocial_conflict: 81.5,
        };

        const target = document.querySelector('#wheelChart');
        let svgP = this.calFindCenterXY(Degrees[point], value, point);

        let numberSelected = value;

        let idName = '';
        let svgOb = {};

        if (svgP.x > $x_center && svgP.y < $y_center) {
          // Part Mind
          idName = $pointMind;
          svgOb = this.calFindCenterXY(Degree.mind, numberSelected, $pointMind);
        } else if (svgP.x < $x_center && svgP.y < $y_center) {
          // Part Body
          idName = $pointBody;
          svgOb = this.calFindCenterXY(Degree.body, numberSelected, $pointBody);
        } else if (svgP.x > $x_center && svgP.y > $y_center) {
          // Part Soul
          idName = $pointSoul;
          svgOb = this.calFindCenterXY(Degree.soul, numberSelected, $pointSoul);
        } else if (svgP.x < $x_center && svgP.y > $y_center) {
          // Part Social Aspects
          let part = WellnessWheel.getPartSocialAspectsClicked(svgP.x, svgP.y);

          // eslint-disable-next-line default-case
          switch (part) {
            case 1:
              idName = $pointSocial_education;
              svgOb = this.calFindCenterXY(
                Degree.socialeducation,
                numberSelected,
                $pointSocial_education,
              );
              break;
            case 2:
              idName = $pointSocial_money;
              svgOb = this.calFindCenterXY(
                Degree.socialMoney,
                numberSelected,
                $pointSocial_money,
              );
              break;
            case 3:
              idName = $pointSocial_success;
              svgOb = this.calFindCenterXY(
                Degree.socialSuccess,
                numberSelected,
                $pointSocial_success,
              );
              break;

            case 4:
              idName = $pointSocial_love;
              svgOb = this.calFindCenterXY(
                Degree.socialLove,
                numberSelected,
                $pointSocial_love,
              );
              break;

            case 5:
              idName = $pointSocial_sexuality;
              svgOb = this.calFindCenterXY(
                Degree.socialSexuality,
                numberSelected,
                $pointSocial_sexuality,
              );
              break;
            case 6:
              idName = $pointSocial_environment;
              svgOb = this.calFindCenterXY(
                Degree.socialEnvironment,
                numberSelected,
                $pointSocial_environment,
              );
              break;
            case 7:
              idName = $pointSocial_conflict;
              svgOb = this.calFindCenterXY(
                Degree.socialConflict,
                numberSelected,
                $pointSocial_conflict,
              );
              break;
          }
        }

        // Remove all old id point circle
        removeElement(idName);
        // End Remove all old id point circle

        this.createCircle(target, idName, numberSelected, svgP, svgOb);
      }

      //------------ End Method Generate Point
    }

    const WellnessWheel = new Wellness();

    //-------------------------------------------------------------------------------
    // Set Effect for point
    let oCircleObj = document.querySelectorAll('.point-circle');

    if (oCircleObj != null) {
      for (let i = 0; i < oCircleObj.length; i++) {
        oCircleObj[i].onmouseenter = function () {
          WellnessWheel.startMove(
            oCircleObj[i],
            PointRadius.min,
            PointRadius.max,
          );
        };
        oCircleObj[i].onmouseleave = function () {
          WellnessWheel.startMove(
            oCircleObj[i],
            PointRadius.max,
            PointRadius.min,
          );
        };
      }
    }

    // End Set Effect for point
    //-----------------------------------------
    //-------------------------------------------------------------------------------

    if (svg != null) {
      let wheelChart = svg.getElementById('wheelChart');
      wheelChart.addEventListener(
        'click',
        function (e) {
          // let container = document.querySelector("#container-chart-button");
          // if (container != null) {
          let t = e.target;

          WellnessWheel.generatePoint(e.clientX, e.clientY, t.parentNode);
          // }
        },
        false,
      );
    }

    // WellnessWheel.generatePointFromApi(document.querySelector('#wheelChart'));

    // $('#btnSavePoint').on('click', function (e) {
    //   console.log(refresh);
    //   setLoading(true);
    //   // eslint-disable-next-line no-unused-vars
    //   let dateSelected = $('#reportrange span').html();

    //   let elementPointMind = document.querySelector('#pointMind');
    //   let elementPointBody = document.querySelector('#pointBody');
    //   let elementPointSoul = document.querySelector('#pointSoul');

    //   let elementPointSocial_love = document.querySelector('#pointSocial_love');
    //   let elementPointSocial_sexuality = document.querySelector(
    //     '#pointSocial_sexuality'
    //   );
    //   let elementPointSocial_money =
    //     document.querySelector('#pointSocial_money');
    //   let elementPointSocial_success = document.querySelector(
    //     '#pointSocial_success'
    //   );
    //   let elementPointSocial_environment = document.querySelector(
    //     '#pointSocial_environment'
    //   );
    //   let elementPointSocial_conflict = document.querySelector(
    //     '#pointSocial_conflict'
    //   );
    //   let elementPointSocial_education = document.querySelector(
    //     '#pointSocial_education'
    //   );
    //   //--------------------------------------------
    //   // -1 ==> is null
    //   let pointMind = WellnessWheel.getPointClicked(elementPointMind);
    //   let pointBody = WellnessWheel.getPointClicked(elementPointBody);
    //   let pointSoul = WellnessWheel.getPointClicked(elementPointSoul);

    //   let pointSocial_love = WellnessWheel.getPointClicked(
    //     elementPointSocial_love
    //   );
    //   let pointSocial_sexuality = WellnessWheel.getPointClicked(
    //     elementPointSocial_sexuality
    //   );
    //   let pointSocial_money = WellnessWheel.getPointClicked(
    //     elementPointSocial_money
    //   );
    //   let pointSocial_success = WellnessWheel.getPointClicked(
    //     elementPointSocial_success
    //   );
    //   let pointSocial_environment = WellnessWheel.getPointClicked(
    //     elementPointSocial_environment
    //   );
    //   let pointSocial_conflict = WellnessWheel.getPointClicked(
    //     elementPointSocial_conflict
    //   );
    //   let pointSocial_education = WellnessWheel.getPointClicked(
    //     elementPointSocial_education
    //   );

    //   const data = {
    //     pointMind: pointMind,
    //     pointBody: pointBody,
    //     pointSoul: pointSoul,
    //     pointSocial_love: pointSocial_love,
    //     pointSocial_sexuality: pointSocial_sexuality,
    //     pointSocial_money: pointSocial_money,
    //     pointSocial_success: pointSocial_success,
    //     pointSocial_environment: pointSocial_environment,
    //     pointSocial_conflict: pointSocial_conflict,
    //     pointSocial_education: pointSocial_education,
    //   };

    //   WheelService.createNewWheel(data)
    //     .then(() => {
    //       message.success('Wheel data saved!');
    //       if (onChange) {
    //         onChange(data);
    //         if (setVisible) {
    //           setVisible(false);
    //         }
    //       }
    //       setLoading(false);
    //       setWheelRefresh((pre) => pre + 1);
    //     })
    //     .catch((error) => console.log(error))
    //     .finally(() => () => setLoading(false));
    // });

    $('#btnResetData').on('click', function (e) {
      points.forEach((point) => removeElement(point));
    });

    setApiLoading(true);
    WheelService.getLastWheel()
      .then((res) => {
        points.forEach((idName) => removeElement(idName));
        return res;
      })
      .then((res) => res.data)
      .then(({ data }) => {
        let { wheel } = data;
        wheel = wheel || {};

        Object.keys(wheel).forEach((key) => {
          if (points.includes(key) && wheel[key]) {
            WellnessWheel.generatePointFromApi(key, parseInt(wheel[key]));
          }
        });
      })
      .finally(() => setApiLoading(false));
  }, [refresh]);

  useEffect(() => {
    const $x_center = 860.58;
    const $y_center = 429.95;

    const $pointMind = 'pointMind';
    const $pointBody = 'pointBody';
    const $pointSoul = 'pointSoul';
    const $pointSocial_love = 'pointSocial_love';
    const $pointSocial_sexuality = 'pointSocial_sexuality';
    const $pointSocial_money = 'pointSocial_money';
    const $pointSocial_success = 'pointSocial_success';
    const $pointSocial_environment = 'pointSocial_environment';
    const $pointSocial_conflict = 'pointSocial_conflict';
    const $pointSocial_education = 'pointSocial_education';

    const $radius = [
      427.9, 387.8, 347.7, 307.6, 267.5, 227.4, 187.3, 147.2, 107.1, 67,
    ];
    const Degree = {
      mind: 51.3,
      body: 38,
      soul: 38,
      socialeducation: 5.5,
      socialMoney: 16.5,
      socialSuccess: 27.5,
      socialLove: 39.5,
      socialSexuality: 51.5,
      socialEnvironment: 66.5,
      socialConflict: 81.5,
    };

    const PointRadius = {
      min: 14,
      max: 18,
    };

    let svg = document.getElementById('wellnessWheel');
    let NS = null;
    if (svg != null) {
      NS = svg.getAttribute('xmlns');
    }

    // Remove all old id point circle
    function removeElement(idName) {
      let Elements = document.querySelectorAll(`#${idName}`);

      for (let i = 0; i < Elements.length; i++) {
        Elements[i].remove();
      }
    }
    // End Remove all old id point circle

    class Wellness {
      //------------------------
      cal_sin(degree) {
        let accuracy = 0.0001;

        // Converting degrees to radian
        degree = degree * (3.142 / 180.0);

        let x1 = degree;

        // maps the sum along the series
        let sinX = degree;

        // holds the actual value of sin(n)
        let sinVal = Math.sin(degree);
        let i = 1;
        do {
          let denominator = 2 * i * (2 * i + 1);
          x1 = (-x1 * degree * degree) / denominator;
          sinX = sinX + x1;
          i++;
        } while (accuracy <= Math.abs(sinVal - sinX));

        return sinX;
      }

      //------------------------
      // Function for calculation
      cal_cos(degree) {
        let accuracy = 0.0001;

        // Converting degrees to radian
        degree = degree * (3.142 / 180.0);

        let x1 = 1;

        // maps the sum along the series
        let cosX = x1;

        // holds the actual value of sin(n)
        let cosVal = Math.cos(degree);
        let i = 1;
        do {
          let denominator = 2 * i * (2 * i - 1);
          x1 = (-x1 * degree * degree) / denominator;
          cosX = cosX + x1;
          i = i + 1;
        } while (accuracy <= Math.abs(cosVal - cosX));

        return cosX;
      }

      //------------------------
      calFindCenterXY(degree, numberSelected, part = 'mind') {
        let math_x = this.cal_cos(degree);
        let math_y = this.cal_sin(degree);

        math_x = ($radius[numberSelected - 1] - 21) * math_x;
        math_y = ($radius[numberSelected - 1] - 21) * math_y;

        let svgPoint = {
          // Default is Mind part calculate
          x: $x_center + math_x,
          y: $y_center - math_y,
        };

        // eslint-disable-next-line default-case
        switch (part) {
          case $pointBody:
            svgPoint.x = $x_center - math_x;
            svgPoint.y = $y_center - math_y;
            break;
          case $pointSoul:
            svgPoint.x = $x_center + math_x;
            svgPoint.y = $y_center + math_y;
            break;
          case $pointSocial_education:
          case $pointSocial_love:
          case $pointSocial_sexuality:
          case $pointSocial_money:
          case $pointSocial_success:
          case $pointSocial_environment:
          case $pointSocial_conflict:
            svgPoint.x = $x_center - math_x;
            svgPoint.y = $y_center + math_y;
            break;
        }
        return svgPoint;
      }

      //------------------------
      // Find Number Of Point Clicked
      getPointClicked(object, x = -1, y = -1) {
        if (object != null) {
          x = object.getAttribute('cx');
          y = object.getAttribute('cy');
          // eslint-disable-next-line eqeqeq
        } else if (x == -1 && object == null) {
          return 0; // null
        }

        x = $x_center - x;
        y = $y_center - y;

        let R = this.getRadius(x, y);

        let point = (R - $radius[9]) / 40.1; // 40.1 space of any radius
        point = Math.floor(10 - point);

        return point;
      }

      //------------------------
      // Find Part Social Aspects Clicked
      getPartSocialAspectsClicked(x, y) {
        x = $x_center - x;
        y = $y_center - y;

        let R = this.getRadius(x, y);

        let degreeSin = -y / R;
        let ret = Math.asin(degreeSin);
        let degree = (ret * 180) / Math.PI;

        let partSocial = Math.floor(degree / 12) + 1;

        return partSocial;
      }

      //------------------------
      getRadius(x, y) {
        let R = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));

        return R;
      }

      //------------------------
      // translate page to SVG
      svgPoint(element, x, y) {
        let pt = svg.createSVGPoint();
        pt.x = x;
        pt.y = y;

        return pt.matrixTransform(element.getScreenCTM().inverse());
      }

      //------------------------
      // Function startMove
      startMove(obj, r1, r2) {
        let nowR = r1;
        let targetR = r2;
        let speed = 0;
        clearInterval(obj.timer);
        obj.timer = setInterval(function () {
          speed += (targetR - nowR) / 6;
          speed *= 0.9;
          if (Math.abs(targetR - nowR) <= 1 && Math.abs(speed) <= 1) {
            clearInterval(obj.timer);
            obj.setAttribute('r', targetR);
          } else {
            nowR += speed;
            obj.setAttribute('r', nowR);
          }
        }, 30);
      }

      createCircle(target, idName, numberSelected, svgP, svgOb) {
        let circle = document.createElementNS(NS, 'circle');

        if (NS == null) {
          return;
        }

        circle.setAttributeNS(null, 'cx', Math.round(svgP.x));
        circle.setAttributeNS(null, 'cy', Math.round(svgP.y));
        circle.setAttributeNS(null, 'r', 14);
        circle.setAttributeNS(null, 'id', idName);
        circle.setAttributeNS(null, 'class', 'point-draw');

        if (target && target != null) target.appendChild(circle);

        circle.onmouseenter = function () {
          WellnessWheel.startMove(circle, PointRadius.min, PointRadius.max);
        };
        circle.onmouseleave = function () {
          WellnessWheel.startMove(circle, PointRadius.max, PointRadius.min);
        };
        // circle.onmousemove = function () {
        //     WellnessWheel.showTooltip(svgOb.x, svgOb.y, 'This is blue');
        // };
        // circle.onmouseout = function () {
        //     WellnessWheel.hideTooltip();
        // };

        setTimeout(function () {
          circle.setAttributeNS(null, 'cx', Math.round(svgOb.x));
          circle.setAttributeNS(null, 'cy', Math.round(svgOb.y));
        }, 500);

        let tooltipSpan = document.createElementNS(NS, 'title');
        let textNode = document.createTextNode(numberSelected.toString());
        tooltipSpan.appendChild(textNode);
        circle.appendChild(tooltipSpan);
      }

      //------------------------
      generatePoint(x, y, target) {
        let svgP = this.svgPoint(target, x, y);

        let numberSelected = this.getPointClicked(null, svgP.x, svgP.y);

        let idName = '';
        let svgOb = {};

        if (svgP.x > $x_center && svgP.y < $y_center) {
          // Part Mind
          idName = $pointMind;
          svgOb = this.calFindCenterXY(Degree.mind, numberSelected, $pointMind);
        } else if (svgP.x < $x_center && svgP.y < $y_center) {
          // Part Body
          idName = $pointBody;
          svgOb = this.calFindCenterXY(Degree.body, numberSelected, $pointBody);
        } else if (svgP.x > $x_center && svgP.y > $y_center) {
          // Part Soul
          idName = $pointSoul;
          svgOb = this.calFindCenterXY(Degree.soul, numberSelected, $pointSoul);
        } else if (svgP.x < $x_center && svgP.y > $y_center) {
          // Part Social Aspects
          let part = WellnessWheel.getPartSocialAspectsClicked(svgP.x, svgP.y);

          // eslint-disable-next-line default-case
          switch (part) {
            case 1:
              idName = $pointSocial_education;
              svgOb = this.calFindCenterXY(
                Degree.socialeducation,
                numberSelected,
                $pointSocial_education,
              );
              break;
            case 2:
              idName = $pointSocial_money;
              svgOb = this.calFindCenterXY(
                Degree.socialMoney,
                numberSelected,
                $pointSocial_money,
              );
              break;
            case 3:
              idName = $pointSocial_success;
              svgOb = this.calFindCenterXY(
                Degree.socialSuccess,
                numberSelected,
                $pointSocial_success,
              );
              break;

            case 4:
              idName = $pointSocial_love;
              svgOb = this.calFindCenterXY(
                Degree.socialLove,
                numberSelected,
                $pointSocial_love,
              );
              break;

            case 5:
              idName = $pointSocial_sexuality;
              svgOb = this.calFindCenterXY(
                Degree.socialSexuality,
                numberSelected,
                $pointSocial_sexuality,
              );
              break;
            case 6:
              idName = $pointSocial_environment;
              svgOb = this.calFindCenterXY(
                Degree.socialEnvironment,
                numberSelected,
                $pointSocial_environment,
              );
              break;
            case 7:
              idName = $pointSocial_conflict;
              svgOb = this.calFindCenterXY(
                Degree.socialConflict,
                numberSelected,
                $pointSocial_conflict,
              );
              break;
          }
        }

        // Remove all old id point circle
        removeElement(idName);
        // End Remove all old id point circle

        this.createCircle(target, idName, numberSelected, svgP, svgOb);
      }

      generatePointFromApi(point, value) {
        const Degrees = {
          pointMind: 51.3,
          pointBody: 38,
          pointSoul: 38,
          pointSocial_education: 5.5,
          pointSocial_money: 16.5,
          pointSocial_success: 27.5,
          pointSocial_love: 39.5,
          pointSocial_sexuality: 51.5,
          pointSocial_environment: 66.5,
          pointSocial_conflict: 81.5,
        };

        const target = document.querySelector('#wheelChart');
        let svgP = this.calFindCenterXY(Degrees[point], value, point);

        let numberSelected = value;

        let idName = '';
        let svgOb = {};

        if (svgP.x > $x_center && svgP.y < $y_center) {
          // Part Mind
          idName = $pointMind;
          svgOb = this.calFindCenterXY(Degree.mind, numberSelected, $pointMind);
        } else if (svgP.x < $x_center && svgP.y < $y_center) {
          // Part Body
          idName = $pointBody;
          svgOb = this.calFindCenterXY(Degree.body, numberSelected, $pointBody);
        } else if (svgP.x > $x_center && svgP.y > $y_center) {
          // Part Soul
          idName = $pointSoul;
          svgOb = this.calFindCenterXY(Degree.soul, numberSelected, $pointSoul);
        } else if (svgP.x < $x_center && svgP.y > $y_center) {
          // Part Social Aspects
          let part = WellnessWheel.getPartSocialAspectsClicked(svgP.x, svgP.y);

          // eslint-disable-next-line default-case
          switch (part) {
            case 1:
              idName = $pointSocial_education;
              svgOb = this.calFindCenterXY(
                Degree.socialeducation,
                numberSelected,
                $pointSocial_education,
              );
              break;
            case 2:
              idName = $pointSocial_money;
              svgOb = this.calFindCenterXY(
                Degree.socialMoney,
                numberSelected,
                $pointSocial_money,
              );
              break;
            case 3:
              idName = $pointSocial_success;
              svgOb = this.calFindCenterXY(
                Degree.socialSuccess,
                numberSelected,
                $pointSocial_success,
              );
              break;

            case 4:
              idName = $pointSocial_love;
              svgOb = this.calFindCenterXY(
                Degree.socialLove,
                numberSelected,
                $pointSocial_love,
              );
              break;

            case 5:
              idName = $pointSocial_sexuality;
              svgOb = this.calFindCenterXY(
                Degree.socialSexuality,
                numberSelected,
                $pointSocial_sexuality,
              );
              break;
            case 6:
              idName = $pointSocial_environment;
              svgOb = this.calFindCenterXY(
                Degree.socialEnvironment,
                numberSelected,
                $pointSocial_environment,
              );
              break;
            case 7:
              idName = $pointSocial_conflict;
              svgOb = this.calFindCenterXY(
                Degree.socialConflict,
                numberSelected,
                $pointSocial_conflict,
              );
              break;
          }
        }

        // Remove all old id point circle
        removeElement(idName);
        // End Remove all old id point circle

        this.createCircle(target, idName, numberSelected, svgP, svgOb);
      }

      //------------ End Method Generate Point
    }

    const WellnessWheel = new Wellness();

    //-------------------------------------------------------------------------------
    // Set Effect for point
    let oCircleObj = document.querySelectorAll('.point-circle');

    if (oCircleObj != null) {
      for (let i = 0; i < oCircleObj.length; i++) {
        oCircleObj[i].onmouseenter = function () {
          WellnessWheel.startMove(
            oCircleObj[i],
            PointRadius.min,
            PointRadius.max,
          );
        };
        oCircleObj[i].onmouseleave = function () {
          WellnessWheel.startMove(
            oCircleObj[i],
            PointRadius.max,
            PointRadius.min,
          );
        };
      }
    }

    // End Set Effect for point
    //-----------------------------------------
    //-------------------------------------------------------------------------------

    // if (svg != null) {
    //   let wheelChart = svg.getElementById('wheelChart');
    //   wheelChart.addEventListener(
    //     'click',
    //     function (e) {
    //       // let container = document.querySelector("#container-chart-button");
    //       // if (container != null) {
    //       let t = e.target;

    //       WellnessWheel.generatePoint(e.clientX, e.clientY, t.parentNode);
    //       // }
    //     },
    //     false
    //   );
    // }

    // WellnessWheel.generatePointFromApi(document.querySelector('#wheelChart'));

    $('#btnSavePoint').on('click', function (e) {
      console.log(refresh);
      setLoading(true);
      // eslint-disable-next-line no-unused-vars
      let dateSelected = $('#reportrange span').html();

      let elementPointMind = document.querySelector('#pointMind');
      let elementPointBody = document.querySelector('#pointBody');
      let elementPointSoul = document.querySelector('#pointSoul');

      let elementPointSocial_love = document.querySelector('#pointSocial_love');
      let elementPointSocial_sexuality = document.querySelector(
        '#pointSocial_sexuality',
      );
      let elementPointSocial_money =
        document.querySelector('#pointSocial_money');
      let elementPointSocial_success = document.querySelector(
        '#pointSocial_success',
      );
      let elementPointSocial_environment = document.querySelector(
        '#pointSocial_environment',
      );
      let elementPointSocial_conflict = document.querySelector(
        '#pointSocial_conflict',
      );
      let elementPointSocial_education = document.querySelector(
        '#pointSocial_education',
      );
      //--------------------------------------------
      // -1 ==> is null
      let pointMind = WellnessWheel.getPointClicked(elementPointMind);
      let pointBody = WellnessWheel.getPointClicked(elementPointBody);
      let pointSoul = WellnessWheel.getPointClicked(elementPointSoul);

      let pointSocial_love = WellnessWheel.getPointClicked(
        elementPointSocial_love,
      );
      let pointSocial_sexuality = WellnessWheel.getPointClicked(
        elementPointSocial_sexuality,
      );
      let pointSocial_money = WellnessWheel.getPointClicked(
        elementPointSocial_money,
      );
      let pointSocial_success = WellnessWheel.getPointClicked(
        elementPointSocial_success,
      );
      let pointSocial_environment = WellnessWheel.getPointClicked(
        elementPointSocial_environment,
      );
      let pointSocial_conflict = WellnessWheel.getPointClicked(
        elementPointSocial_conflict,
      );
      let pointSocial_education = WellnessWheel.getPointClicked(
        elementPointSocial_education,
      );

      const data = {
        pointMind: pointMind,
        pointBody: pointBody,
        pointSoul: pointSoul,
        pointSocial_love: pointSocial_love,
        pointSocial_sexuality: pointSocial_sexuality,
        pointSocial_money: pointSocial_money,
        pointSocial_success: pointSocial_success,
        pointSocial_environment: pointSocial_environment,
        pointSocial_conflict: pointSocial_conflict,
        pointSocial_education: pointSocial_education,
      };

      WheelService.createNewWheel(data)
        .then(() => {
          message.success('Wheel data saved!');
          if (onChange) {
            onChange(data);
            if (setVisible) {
              setVisible(false);
            }
          }
          setLoading(false);
          setWheelRefresh((pre) => pre + 1);
        })
        .catch((error) => console.log(error))
        .finally(() => () => setLoading(false));
    });

    $('#btnCalc').on('click', function (e) {
      // eslint-disable-next-line no-unused-vars
      let dateSelected = $('#reportrange span').html();

      let elementPointMind = document.querySelector('#pointMind');
      let elementPointBody = document.querySelector('#pointBody');
      let elementPointSoul = document.querySelector('#pointSoul');

      let elementPointSocial_love = document.querySelector('#pointSocial_love');
      let elementPointSocial_sexuality = document.querySelector(
        '#pointSocial_sexuality',
      );
      let elementPointSocial_money =
        document.querySelector('#pointSocial_money');
      let elementPointSocial_success = document.querySelector(
        '#pointSocial_success',
      );
      let elementPointSocial_environment = document.querySelector(
        '#pointSocial_environment',
      );
      let elementPointSocial_conflict = document.querySelector(
        '#pointSocial_conflict',
      );
      let elementPointSocial_education = document.querySelector(
        '#pointSocial_education',
      );
      //--------------------------------------------
      // -1 ==> is null
      let pointMind = WellnessWheel.getPointClicked(elementPointMind);
      let pointBody = WellnessWheel.getPointClicked(elementPointBody);
      let pointSoul = WellnessWheel.getPointClicked(elementPointSoul);

      let pointSocial_love = WellnessWheel.getPointClicked(
        elementPointSocial_love,
      );
      let pointSocial_sexuality = WellnessWheel.getPointClicked(
        elementPointSocial_sexuality,
      );
      let pointSocial_money = WellnessWheel.getPointClicked(
        elementPointSocial_money,
      );
      let pointSocial_success = WellnessWheel.getPointClicked(
        elementPointSocial_success,
      );
      let pointSocial_environment = WellnessWheel.getPointClicked(
        elementPointSocial_environment,
      );
      let pointSocial_conflict = WellnessWheel.getPointClicked(
        elementPointSocial_conflict,
      );
      let pointSocial_education = WellnessWheel.getPointClicked(
        elementPointSocial_education,
      );

      const data = {
        pointMind: pointMind,
        pointBody: pointBody,
        pointSoul: pointSoul,
        pointSocial_love: pointSocial_love,
        pointSocial_sexuality: pointSocial_sexuality,
        pointSocial_money: pointSocial_money,
        pointSocial_success: pointSocial_success,
        pointSocial_environment: pointSocial_environment,
        pointSocial_conflict: pointSocial_conflict,
        pointSocial_education: pointSocial_education,
      };

      const values = Object.values(data);
      const sum = values.reduce((a, b) => a + b, 0);

      const notZeros = Object.keys(data).filter((key) => data[key]);

      const result = `${sum}/${notZeros.length * 10}`;

      // document.querySelector('.summary').innerHTML = ReactDmo;

      ReactDOM.render(
        <Typography.Title level={5}>Total: {result}</Typography.Title>,
        document.querySelector('.summary'),
      );
    });
  }, []);

  // const onSaveClick = () => {};

  return (
    <>
      <PageTitle title="My Life Target" />

      <Spin spinning={apiLoading} tip="Loading last data...">
        <Row justify="start">
          <Col span={12}>
            <WheelSVG modal={imgModal} setModal={setImgModal} />
          </Col>
          <Col span={12}>
            {imgModal.show ? (
              <img src={imgModal.image} alt="info" style={{ width: '100%' }} />
            ) : null}
          </Col>
        </Row>

        <Row justify="space-between" align="bottom">
          <Space>
            <Button
              // onClick={onSaveClick}
              loading={loading}
              type="primary"
              style={{ marginTop: '30px' }}
              id="btnSavePoint"
            >
              Save
            </Button>

            <Button
              // onClick={onSaveClick}
              disabled={loading}
              type="default"
              style={{ marginTop: '30px' }}
              id="btnCalc"
            >
              Calculate
            </Button>

            <Button
              // onClick={onSaveClick}
              disabled={loading}
              type="default"
              style={{ marginTop: '30px' }}
              id="btnResetData"
            >
              Reset
            </Button>
          </Space>

          <div className="summary"></div>
        </Row>
      </Spin>

      <WheelHelpers />

      <Collapse
        accordion
        style={{ marginTop: '20px', display: modal ? 'none' : '' }}
      >
        <Panel header="History" key="1">
          <WheelTable
            refresh={refresh}
            setRefresh={setRefresh}
            wheelRefresh={wheelRefresh}
          />
        </Panel>
      </Collapse>
    </>
  );
};

export default MyLifeWheelPage;
