import { message } from 'antd';
import AuthService from '../../services/api/AuthService';
import UserService from '../../services/api/UserService';
import {
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  REFRESH_USER,
  REGISTER_FAIL_REDIRECT_REFERAL,
  LOGIN_REQUEST_REFERAL,
  LOGIN_SUCCESS_REFERAL,
  LOGIN_FAIL_REFERAL,
  REGISTER_SUCCESS
} from './types';

export const refreshUser = () => async (dispatch) => {
  try {
    const res = await UserService.getProfile();
    const { user } = res.data.data;

    dispatch({ type: REFRESH_USER, payload: { user } });
  } catch (error) {
    // dispatch({ type: LOGOUT });
  }
};

export const autoLoginUser = () => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });

  try {
    const res = await UserService.getProfile();
    const { user } = res.data.data;

    dispatch({ type: LOGIN_SUCCESS, payload: { user } });
  } catch (error) {
    dispatch({ type: LOGOUT });
  }
};

export const loginUser = (wpUserId) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  try {
    const res = await AuthService.login(wpUserId);
    const { user } = res.data.data;

    localStorage.setItem('user', JSON.stringify(user));

    const redirectTo = user?.new ? '/wheel' : '/';
    dispatch({ type: LOGIN_SUCCESS, payload: { user, redirectTo } });
    message.success('You have successfully logged in.');
  } catch (error) {
    dispatch({ type: LOGIN_FAIL });
    message.error('Sorry, there is an error logging.');
  }
};
export const loginWPEmail = (wpUserEmail) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  try {
    const res = await AuthService.loginWPEmail(wpUserEmail);
    const { user } = res.data.data;

    localStorage.setItem('user', JSON.stringify(user));

    const redirectTo = user?.new ? '/wheel' : '/';
    dispatch({ type: LOGIN_SUCCESS, payload: { user, redirectTo } });
    message.success('You have successfully logged in.');
  } catch (error) {
    dispatch({ type: LOGIN_FAIL });
    message.error('Sorry, there is an error logging.');
  }
};

export const loginUserWithEmail = (userInput) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  try {
    const res = await AuthService.loginWithEmail(userInput);
    const { user,redirectTo } = res.data.data;
    localStorage.setItem('user', JSON.stringify(user));
    if(redirectTo)
    {
    dispatch({ type: LOGIN_SUCCESS, payload: { user, redirectTo } });
 
    }else{

   
    const redirectTo = user?.new ? '/wheel' : '/my-health-wellness';
    dispatch({ type: LOGIN_SUCCESS, payload: { user, redirectTo } });
   }
    message.success('You have successfully logged in.');
  } catch (error) {
    dispatch({ type: LOGIN_FAIL });
    const errMessage = error?.response?.data?.message;
    dispatch({ type: LOGIN_FAIL });
    message.error(errMessage);
  }
};

export const registerUserWithEmail = (userInput) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  try {
    const res = await AuthService.signupWithEmail(userInput);
    const { user } = res.data.data;

    localStorage.setItem('user', JSON.stringify(user));

    const redirectTo = user?.new ? '/wheel' : '/';
    dispatch({ type: LOGIN_SUCCESS, payload: { user, redirectTo } });
    message.success('You have successfully logged in.');
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({ type: LOGIN_FAIL });
    message.error(errMessage);
  }
};
export const registerUserWithReferal= (userInput) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST_REFERAL });

  try {
    const res = await AuthService.signupWithReferal(userInput);

    if(res.data.referalURL)
    {
      message.success('Email already exist, Please login');
      // redirect to login with referal
      const redirectTo = res.data.referalURL;
      // dispatch(logoutUser());
      dispatch({ type: REGISTER_FAIL_REDIRECT_REFERAL, payload: { redirectTo } });
    } else {
      const { user } = res.data.data;

      localStorage.setItem('user', JSON.stringify(user));

      const redirectTo = user?.new ? '/wheel' : '/';
      dispatch({ type: REGISTER_SUCCESS, payload: { user, redirectTo } });
      message.success('Registeration  successful. please login.');
    }
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({ type: LOGIN_FAIL });
    message.error(errMessage);
  }
};

export const loginUserWithReferal = (userInput) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST_REFERAL });
  try {
    const res = await AuthService.signinWithReferal(userInput);
    const { user } = res.data.data;
    localStorage.setItem('user', JSON.stringify(user));

    const redirectTo = user?.new ? '/wheel' : '/';
    dispatch({ type: LOGIN_SUCCESS_REFERAL, payload: { user, redirectTo } });
    message.success('You have successfully logged in.');
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({ type: LOGIN_FAIL_REFERAL });
    message.error(errMessage);
  }
};

export const logoutUser = () => {
  localStorage.removeItem('user');

  return {
    type: LOGOUT,
  };
};
