import { Result, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const Unauthorized = () => {
  const location = useLocation()
  const quer = location.state.query;
  const value = location.state.redirection.pathname+quer ;
  let urlRedirect ;
  if(value)
  {
     urlRedirect = `${process.env.PUBLIC_URL}/login?redirect=${value}`;
  } else {
     urlRedirect = `${process.env.PUBLIC_URL}/login`;
  }

  useEffect(() => window.location.replace(urlRedirect), []);

  return (
    <Result icon={<Spin size="large" />} title="Unauthorized, please wait..." />
  );
};

export default Unauthorized;
