// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site-layout-background {
  background-color: #fff;
}
.ant-layout-sider {
  background: #707071 !important;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #707071 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/app-layout/styles.less"],"names":[],"mappings":"AAAA;EAEE,sBAAA;AAAF;AAGA;EACE,8BAAA;AADF;AAMA;;;EAGE,8BAAA;AAJF","sourcesContent":[".site-layout-background {\n  // background-color: #ffffff00;\n  background-color: #fff;\n}\n\n.ant-layout-sider {\n  background: #707071 !important;\n  // background: #939393 !important;\n\n}\n\n.ant-menu.ant-menu-dark,\n.ant-menu-dark .ant-menu-sub,\n.ant-menu.ant-menu-dark .ant-menu-sub {\n  background: #707071 !important;\n  // background: #666666 !important;\n\n}\n\n#app-layout .sidebar {\n  // width: 250px !important;\n  // max-width: 250px !important;\n  // min-width: 250px !important;\n}\n\n\n@primary-color: #a700ba;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
